import React, { useEffect, useState } from "react";
import "../scss/signup.scss";
// import Signupnavbar from "../../../components/signup_navbar/jsx/Signupnavbar";
import Navbar from "../../../components/navbar/jsx/Navbar"
import { NavLink, useNavigate } from "react-router-dom";
import { chatNexoBackend } from "../../../services/chat_nexo_backend";
import toast from "react-hot-toast";
import { ROUTES } from "../../../utils/Constansts";
import image from "../../../assets/image/image 1.png";
// import EditIcon from '@mui/icons-material/Edit';
// import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import PhoneForwardedRoundedIcon from '@mui/icons-material/PhoneForwardedRounded';

import useQuery from "../../../hooks/useQuery";
// import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';


const Signup = ({ saveEmail }) => {
  const [userData, setUserData] = useState({});
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobilenumber, setMobikenumber] = useState("");
  const [password, setPassword] = useState("");
  const [showinput, setShowinput]=useState(false);
  const query = useQuery();

  const emailname=query.get("email") || "";
  const name1=query.get("name") || "";
  const navigate = useNavigate();

  const handelbuttonclickinput = ()=>{
    setShowinput(!showinput);
  }

  useEffect(() => {
    const savedData = localStorage.getItem("USER_DATA");
    if (savedData) {
      setUserData(JSON.parse(savedData));
    }
  }, []);

  const handelsendotp =()=>{
    toast.success("Otp send success");
  }

  const handleSignUp = async () => {
    if (!email.trim().length || !password.trim().length) return;

    const response = await chatNexoBackend.signUp({
      ...userData,
      email,
      password,
      countryCode: `+${userData.countryCode}`,
    });

    if (response.data.status === "SUCCESS") {
      saveEmail(email);
      navigate(ROUTES.VERIFY_OTP);
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <div className="login">
      <div className="login_navbar">
        <Navbar/>
      </div>

      <div className="signup_main">
        <div className={showinput ? "signup_card active" : "signup_card"}>
          <div className="signup_card_top">
            <h1>Log In or Sign Up</h1>
            <p>Get started for free</p>
          </div>

          <div className="signup_card_top_logo">

            <div className="signup_card_main">
              <img src={image} alt="" />
              <p>Continue with Google</p>

            </div>

            <div className="signup_card_break">
              <div className="line"></div>
              or
              <div className="line"></div>              

            </div>

          </div>
          
          <div className={showinput ? "signup_card_middle active" : 'signup_card_middle'}>

          
              <div className={emailname?"card_input_1":"card_input_1"}>
                <span> Work email </span>
                <div className={!emailname ? "card_input_input active" : "card_input_input"}>
                  <input
                    type="email"
                    placeholder={emailname}
                    value={emailname}
                    disabled={(emailname?true:false)}
                    onChange={(e) => {
                      const email = e.target.value;
                      setEmail(email);
                    }}
                    style={{ cursor: (showinput)||(emailname) ? 'not-allowed' : '' }}

                  />
                </div>
                
              </div>  
          
            

            <div className="card_input_2">
              <span> Name</span>

              <div className={showinput ? "card_input_input active" : "card_input_input"}>
                <input
                  type="email"
                  placeholder={name1}
                  value={name}
                  disabled={(name1?true:false)}
                  onChange={(e) => {
                    const email = e.target.value;
                    setName(email);
                  }}
                  style={{ cursor: (name1) ? 'not-allowed' : '' }}
                />
                
              </div>
              
            </div>

            <div className="card_input">
              <span> Whatsapp Number</span>

              <div className={showinput ? "card_input_input active" : "card_input_input"}>
                <input
                  type="tel"
                  placeholder="Enter your number"
                  value={mobilenumber}
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" 
                  disabled={showinput}
                  onChange={(e) => {
                    const number = e.target.value;
                    setMobikenumber(number);
                  }}
                  style={{ cursor: showinput ? 'not-allowed' : '' }}
                />
                {showinput && <PhoneForwardedRoundedIcon className="icon" onClick={handelbuttonclickinput} titleAccess="Edit" />}
                

              </div>
              
            </div>

            {showinput &&<div className="card_input">
              <span>Verification code</span>
              <input
                type="text"
                placeholder="Paste sign up code"
                value={password}
                onChange={(e) => {
                  const password = e.target.value;
                  setPassword(password);
                }}
              />
              <p>We sent a verification code to your inbox <span onClick={handelsendotp} >Resend</span> </p>
            </div> }

            

          </div>

          <div className="signup_card_bottom">
            <div className="bottom_button">
              {!showinput ? <button onClick={handelbuttonclickinput}>Verify</button> :<button onClick={handleSignUp}>Verify Otp</button>}
            </div>
            <p>
              By clicking “Continue with Google” ,you agree to our{" "}
              <span>
                {" "}
                <NavLink to={ROUTES.TERMS_CONDITIONS} className="nav-link_2">
                  Terms and
                </NavLink>{" "}
              </span>{" "}
              <br />
              <span>
                {" "}
                <NavLink to={ROUTES.TERMS_CONDITIONS} className="nav-link_2">
                  Conditions
                </NavLink>
              </span>{" "}
              and{" "}
              <span>
                {" "}
                <NavLink className="nav-link_2" to={ROUTES.PRIVACY}>
                  Privacy Policy.
                </NavLink>{" "}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
