import React from "react";
import "../scss/home.scss";
import Navbar from "../../../components/navbar/jsx/Navbar";

import main_img from "../../../assets/image/Main.png";
import ElectricBoltRoundedIcon from "@mui/icons-material/ElectricBoltRounded";
import CreditCardTwoToneIcon from "@mui/icons-material/CreditCardTwoTone";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import { Config } from "../../../Config";

const Home = () => {
  const clickroute = (item) => {
    window.open(item);
  };

  return (
    <div className="home">
      <div className="home_navbar">
        <Navbar />
      </div>

      <div className="home_verson_button">
        <button>Replace your crm with WhatsApp </button>
      </div>

      <div className="home_heading">
        <h1>
          Boost your productivity at work <br /> with Whatsapp
        </h1>
        <p>
          No chatbot or WhatsApp API. No Single Number, Help your team chat on a
          larger scale with regular <br /> WhatsApp app number
        </p>

        <div className="home_heading_buttons">
          <button
            className="but-1"
            onClick={() =>
              clickroute(
                Config.CHAT_NEXO_CALENDY
              )
            }
          >
            Book Demo
          </button>
          <button
            className="but-2"
            onClick={() =>
              clickroute(
                Config.CHAT_NEXO_CHROME_DOWNLOAD
              )
            }
          >
            Add to chrome
          </button>
        </div>

        <div className="home_heading_flash_creditcard">
          <div className="home_heading_cards">
            <ElectricBoltRoundedIcon
              style={{ color: "gold", fontSize: "20px" }}
            />
            <h6>2 min setup</h6>
          </div>

          <div className="home_heading_cards">
            <FiberManualRecordRoundedIcon style={{ fontSize: "8px" }} />
          </div>

          <div className="home_heading_cards">
            <CreditCardTwoToneIcon style={{ fontSize: "20px" }} />
            <h6>No credit card required</h6>
          </div>
        </div>
      </div>

      <div className="home_vedio">
        <div className="home_vedio_vedio">
          <img src={main_img} alt="" />
        </div>
      </div>

      <div className="home_footer"></div>
    </div>
  );
};

export default Home;
