import React,{useState,useEffect} from 'react'
import axios from 'axios';
import { Config } from '../../../Config';
import "../scss/paymentprocessing.scss";
import useQuery from '../../../hooks/useQuery';
import Modal1 from './Modal1';

import image2 from "../../../assets/image/checkmark.gif"

const Paymentprocessing = ({accestoken}) => {
    const [issucces,setIssucces]= useState(false);
    const [showModal1, setShowModal1] = useState(false);
    const query = useQuery();
    const transactionId = query.get("transactionId") || "";

    useEffect(() => {
        const fetchPlans = async () => {
          try {
            const response = await axios.get(`${Config.CHAT_NEXO_BASE_URL}/v1/subscription/payment-status?transactionId=${transactionId}`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
              }
            });
            console.log(response);
            const isDone = response.status === 200 ? true : false;
            setIssucces(isDone);
            if (isDone) {
                setShowModal1(true);
            }
          } catch (error) {
            console.error("Error fetching the plans:", error);
          }
        };
        fetchPlans();
      }, [transactionId]);


    const handleCloseModal1 = () => {
        localStorage.removeItem('accessToken');
        setShowModal1(false);
    };
    
  return (
    <div className='payment_processing'>
        {issucces && <Modal1 show={showModal1} onClose={handleCloseModal1} image={image2} message="Congratulations! Welcome to Chat Nexo!" />}
    </div>
  )
}

export default Paymentprocessing