import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/modal.css";
import { ROUTES } from "../../../utils/Constansts";
import { Config } from "../../../Config";
// import logo from "../../../assets/image/chat_logo_1.png"

const Modal = ({ show, onClose, image, message }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
        navigate(ROUTES.HOME);
        // Open a new tab and navigate to another page after 1 second delay
        setTimeout(() => {
          // window.open(Config.GOOGLE_FORMS, '_blank');
          window.location.href = Config.GOOGLE_FORMS;
        }, 300);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose, navigate]);

  return (
    <div className={`modal-overlay ${show ? "show" : ""}`}>
      <div className={`modal-content ${show ? "scale-up" : "scale-down"}`}>
        <img src={image} alt="Congratulations" />
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Modal;
