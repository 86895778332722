import React from "react";
import "../scss/productivity.scss";

// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EventAvailableRoundedIcon from "@mui/icons-material/EventAvailableRounded";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
// import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

// const buttons = [
//   {name:"Reach your customers faster",text:"Send messages to unsaved contacts. No need to save numbers before contacting."},
//   {name:"Import & Export contacts",text:"Import and export a large number of contacts in bulk via CSV. Breeze through your contact management."},
//   {name:"Reminders & Notifications",text:"Schedule reminders and receive notifications for important events, messages, or tasks and stay updated"},
//   {name:"Analyze your Whatsapp",text:"Analyze the number of messages sent, received, and many more."}
// ]

const Productivity = () => {
  // const [selectedButton, setSelectedButton] = useState(0);

  // const handleButtonClick = (index) => {
  //   setSelectedButton(index);
  // };
  return (
    <div className="productivity">
      <div className="productivity_nav">
        <h1>Boost your whatsApp Productivity</h1>
        <p>5X your Whatsapp Productivity by using these amazing features</p>
      </div>

      <div className="productivity_crm_card">
        <div className="center_it">
          <div className="crm_cards_1">
            <div className="crm_card_icon">
              <EventAvailableRoundedIcon className="icon_2" />
            </div>
            <h4>
              WhatsApp <br /> as CRM
            </h4>
            <p>
              Organize Chats inside Labels <br /> and Funnels
            </p>
          </div>

          <div className="crm_cards_1">
            <div className="crm_card_icon">
              <AccessTimeOutlinedIcon className="icon_2" />
            </div>
            <h4>
              Schedule <br /> Messages
            </h4>
            <p>
              {" "}
              Schedule Individual and <br /> Recurring Messages
            </p>
          </div>
        </div>

        <div className="center_it">
          <div className="crm_cards_1">
            <div className="crm_card_icon">
              <CheckBoxOutlinedIcon className="icon_2" />
            </div>
            <h4>
              Active <br /> Chats
            </h4>
            <p>
              Free yourself from WhatsApp <br /> Clutter
            </p>
          </div>

          <div className="crm_cards_1">
            <div className="crm_card_icon">
              <CommentOutlinedIcon className="icon_2" />
            </div>
            <h4>
              Quick <br /> Replies
            </h4>
            <p>
              Create Personal and Shared Quick <br />
              Replies with attachments
            </p>
          </div>
        </div>
      </div>

      {/* <div className="productivity_features_heading">
        <h1>Somemore features offered by chatnexo</h1>
        <p>Enhance productivity within WhatsApp with tools designed to efficiency and scale.</p>
      </div> */}

      {/* <div className="productivity_featires"> */}

      {/* <div className="productivity_featires_card"> */}
      {/* <div className="productivity_featires_card_navbar">
            <div className="productivity_featires_card_navbar_buttons">
              {buttons.map((item,index)=>(
                <button
                key={index}
                className={selectedButton === index ? "active" : ""}
                onClick={() => handleButtonClick(index)}
                style={{
                  backgroundColor: selectedButton === index ? 'white' : 'transparent',
                  color: selectedButton === index ? 'black' : 'white'
                }}
              >
                {item.name}
              </button>
              ))}
            </div>

          </div> */}

      {/* <div className="productivity_features_main">

            <div className="productivity_features_main_card">
              <div className="productivity_features_main_card_maim"></div>
              <div className="productivity_features_main_card_bottom">
                <p>{selectedButton !== null ? buttons[selectedButton].text : ''}</p>

                <button>Explore all features <ArrowForwardIosRoundedIcon className='icons_5' /> </button>

                {/* <a class="codepen-button"><span>Start Coding</span></a> */}

      {/* <button className="btn" type="button">
                  <strong>Explore all features</strong>
                  <div id="container-stars">
                    <div id="stars"></div>
                  </div>

                  <div id="glow">
                    <div class="circle"></div>
                    <div class="circle"></div>
                  </div>
                </button> */}
      {/* <button>Explore all features</button> */}
      {/* </div> */}

      {/* </div> */}
      {/* </div> */}

      {/* </div> */}

      {/* </div> */}
    </div>
  );
};

export default Productivity;
