import React from 'react'
import "../scss/compareplans.scss";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';

const Inbox_productivity = [
    {name:"Synced messages /seat/month "},
    {name:"Auto sync messages"},
    {name:"Manual sync messages"},
    {name:"Whatsapp group chats"},
    {name:"Custom auto - sync"},
    {name:"Sync messages to companies"},
    {name:"Broadcasting"},
    {name:"Chat with non - contacts"},

    {name:"Chat notes "},
    {name:"Chat reminders"},
    {name:"Share live chats"},
    {name:"Share chats"},
    {name:"Import contacts"},
    {name:"Export contacts"},
    {name:"Unread tab"},
    {name:"Awaiting reply tab"},

    {name:"Need reply tab "},
    {name:"Groups tab"},
    {name:"1:1 tab"},
    {name:"Official accounts tab"},
    {name:"Mentions tab"},
    {name:"Starred tab"},
    {name:"Closed tab"},
    {name:"Custom tabs"},
]

const messages_sync = [
    {name:"Close chats"},
    {name:"Snooze chats"},
    {name:"Star chats"},
    {name:"Sort chats"},
    {name:"Archive chats"},
    {name:"Message templates"},
    {name:"Sync messages to deals"},
    {name:"Sync messages to tickets"},
]

const Security_privacy = [
    {name:"SOC 2 Type II Certified"},
    {name:"OAuth based SSO (Google)"}
]

const Support = [
    {name:"Chat Nexo support via Email "},
    {name:"Chat Nexo support via Live chat"},
    {name:"Onboarding Assistance"},
]

const Admit_controls = [
    {name:"Team Builling"},
    {name:"User roles and management"}
]

const Integrations = [
    {name:"Salesforce integration "},
    {name:"Zapier integration"},
    {name:"Webhook integration"},
]


const Compareplans = () => {

  return (
    <div className='compare'   >
        <div className="compare_nav">
            <h1>Compare plans & Explore all features</h1>
        </div>

        <div className="comapre_main"  >
            <div className="compare_main_card"  >
                <div className="compare_main_card_nav">
                    <div className="compare_main_card_nav_1">
                        <h3>Features</h3>
                    </div>
                    <div className="compare_main_card_nav_2">
                        <h3>Basic</h3>
                        <h3>Business</h3>
                        <h3>Professional</h3>
                    </div>
    
                </div>

                <div className="compare_main_card_dropdown">

                <Accordion className='accordian' >
                    <AccordionSummary
                        className='acoordian_heading'
                        expandIcon={<ExpandMoreIcon className='icon_2' />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <p>Message syncing</p>
                        
                    </AccordionSummary>

                    {
                        messages_sync.map((item,index)=>(

                            <AccordionDetails className='accordian_details' key={index} >
                                <div className="accordian_deatails_1">
                                    <p>{item.name}</p>

                                </div>
                                <div className="accordian_deatails_2">
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    
                                </div>
                                
                            </AccordionDetails>
                        ))
                    }
                    
                </Accordion>

                <Accordion className='accordian' >
                    <AccordionSummary
                        className='acoordian_heading'
                        expandIcon={<ExpandMoreIcon className='icon_2' />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <p>Integrations</p>
                        
                    </AccordionSummary>

                    {
                        Integrations.map((item,index)=>(

                            <AccordionDetails className='accordian_details' key={index} >
                                <div className="accordian_deatails_1">
                                    <p>{item.name}</p>

                                </div>
                                <div className="accordian_deatails_2">
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    
                                </div>
                                
                            </AccordionDetails>
                        ))
                    }
                    
                </Accordion>

                <Accordion className='accordian' >
                    <AccordionSummary
                    
                        className='acoordian_heading'
                        expandIcon={<ExpandMoreIcon className='icon_2' />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <p>Inbox productivity</p>
                        
                    </AccordionSummary>

                    {
                        Inbox_productivity.map((item,index)=>(

                            <AccordionDetails className='accordian_details' key={index} >
                                <div className="accordian_deatails_1">
                                    <p>{item.name}</p>

                                </div>
                                <div className="accordian_deatails_2">
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    
                                </div>
                                
                            </AccordionDetails>
                        ))
                    }
                    
                </Accordion>

                <Accordion className='accordian' >
                    <AccordionSummary
                    
                        className='acoordian_heading'
                        expandIcon={<ExpandMoreIcon className='icon_2' />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <p>Security & privacy</p>
                        
                    </AccordionSummary>

                    {
                        Security_privacy.map((item,index)=>(

                            <AccordionDetails className='accordian_details' key={index} >
                                <div className="accordian_deatails_1">
                                    <p>{item.name}</p>

                                </div>
                                <div className="accordian_deatails_2">
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    
                                </div>
                                
                            </AccordionDetails>
                        ))
                    }
                    
                </Accordion>

                <Accordion className='accordian' >
                    <AccordionSummary
                    
                        className='acoordian_heading'
                        expandIcon={<ExpandMoreIcon className='icon_2' />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <p>Support</p>
                        
                    </AccordionSummary>

                    {
                        Support.map((item,index)=>(

                            <AccordionDetails className='accordian_details' key={index} >
                                <div className="accordian_deatails_1">
                                    <p>{item.name}</p>

                                </div>
                                <div className="accordian_deatails_2">
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    
                                </div>
                                
                            </AccordionDetails>
                        ))
                    }
                    
                </Accordion>

                <Accordion className='accordian_1' >
                    <AccordionSummary 
                    
                        className='acoordian_heading'
                        expandIcon={<ExpandMoreIcon className='icon_2' />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        >
                            <p>Admit controls</p>
                        
                    </AccordionSummary>

                    {
                        Admit_controls.map((item,index)=>(

                            <AccordionDetails className='accordian_details' key={index} >
                                <div className="accordian_deatails_1">
                                    <p>{item.name}</p>

                                </div>
                                <div className="accordian_deatails_2">
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    <DoneRoundedIcon/>
                                    
                                </div>
                                
                            </AccordionDetails>
                        ))
                    }
                    
                </Accordion>

                    
                    

                </div>

            </div>

        </div>


    </div>
  )
}

export default Compareplans