import React, { useEffect, useState } from "react";
import "../scss/sign.scss";
import Navbar from "../../../components/navbar/jsx/Navbar";
import {useNavigate } from "react-router-dom";
import { chatNexoBackend } from "../../../services/chat_nexo_backend";
import toast from "react-hot-toast";
import { ROUTES } from "../../../utils/Constansts";
import image from "../../../assets/image/image 1.png";
import EditIcon from "@mui/icons-material/Edit";

const Login = ({setAccestoken}) => {
  const [userData, setUserData] = useState({});
  const [email, setEmail] = useState("");
  const [OTP, setOTP] = useState("");
  const [showinput, setShowinput] = useState(false);

  const navigate = useNavigate();



  useEffect(() => {
    const url = window.location.href;
    const params = new URLSearchParams(url.split("?")[1]);

    const mobileNumber = params.get("mobileNumber");
    const countryCode = params.get("countryCode")?.replace(/\s/g, "");
    const userName = params.get("userName");

    setUserData({ mobileNumber, countryCode, userName });
  }, []);

  const openRouteInNewWindow = (path) => {
    const url = `${window.location.origin}${path}`;
    window.open(url, '_blank');
  };

  const handelbuttonclickinput = async () => {
    if (showinput === false) {
      if (!email.trim().length) return;

      const response = await chatNexoBackend.signUp({
        email,
        ...(userData.countryCode && {
          countryCode: `+${userData.countryCode}`,
        }),
        ...(userData.mobileNumber != null && {
          mobileNumber: `${userData.mobileNumber}`,
        }),
        ...(userData.userName != null && {
          userName: `${userData.userName}`,
        }),
      });

      if (response.data.status === "SUCCESS") {
        toast.success(response.data.message);
        setShowinput(!showinput);
      } else {
        toast.error(response.data.message);
      }
    } else {
      setShowinput(!showinput);
    }
  };

  const handelsendotp = () => {
    handelbuttonclickinput();
  };

  const handleSignUp = async () => {
    const response = await chatNexoBackend.verifyOtp({ email, otp: OTP });
    localStorage.setItem('accessToken', response.data.result.accessToken);
    if (response.data.status === "SUCCESS") {
      navigate(ROUTES.HOME);
      window.open("https://web.whatsapp.com/","_blank");
    } else {
      toast.error(response.data.message);
    }
  };

  return (
    <div className="login">
      <div className="login_navbar">
        <Navbar />
      </div>

      <div className="signup1_main">
        <div className={showinput ? "signup1_card active" : "signup1_card"}>
          <div className="signup1_card_top">
            <h1>Log In or Sign Up</h1>
            <p>Get started for free</p>
          </div>

          <div className="signup1_card_top_logo">
            <div className="signup1_card_main">
              <img src={image} alt="" />
              <p>Continue with Google</p>
            </div>

            <div className="signup1_card_break">
              <div className="line"></div>
              or
              <div className="line"></div>
            </div>
          </div>

          <div
            className={
              showinput ? "signup1_card_middle active" : "signup1_card_middle"
            }
          >
            <div className="card1_input">
              <span>Email</span>

              <div
                className={
                  showinput ? "card1_input_input active" : "card1_input_input"
                }
              >
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  disabled={showinput}
                  onChange={(e) => {
                    const email = e.target.value;
                    setEmail(email);
                  }}
                  style={{ cursor: showinput ? "not-allowed" : "" }}
                />
                {showinput && (
                  <EditIcon
                    className="icon"
                    onClick={handelbuttonclickinput}
                    titleAccess="Edit"
                  />
                )}
              </div>
            </div>

            {showinput && (
              <div className="card1_input">
                <span>Verification code</span>
                <input
                  type="text"
                  placeholder="Paste sign up code"
                  value={OTP}
                  onChange={(e) => {
                    const enteredOtp = e.target.value.trim();
                    setOTP(enteredOtp);
                  }}
                />
                <p>
                  We sent a verification code to your inbox{" "}
                  <span onClick={handelsendotp}>Resend</span>{" "}
                </p>
              </div>
            )}
          </div>

          <div className="signup1_card_bottom">
            <div className="bottom1_button">
              {!showinput ? (
                <button onClick={handelbuttonclickinput}>
                  Continue with email
                </button>
              ) : (
                <button onClick={handleSignUp}>Verify Otp</button>
              )}
            </div>
            <p>
              By clicking “Continue with Google” ,you agree to our{" "}
              <span  onClick={()=> openRouteInNewWindow(ROUTES.TERMS_CONDITIONS)} >
                {" "}
                {/* <NavLink to={ROUTES.TERMS_CONDITIONS} className="nav-link_2"> */}
                  Terms and
                {/* </NavLink>{" "} */}
              </span>{" "}
              <br />
              <span  onClick={()=> openRouteInNewWindow(ROUTES.TERMS_CONDITIONS)} >
                {" "}
                {/* <NavLink to={ROUTES.TERMS_CONDITIONS} className="nav-link_2"> */}
                  Conditions
                {/* </NavLink> */}
              </span>{" "}
              and{" "}
              <span  onClick={()=> openRouteInNewWindow(ROUTES.PRIVACY)} >
                {" "}
                {/* <NavLink className="nav-link_2" to={ROUTES.PRIVACY}> */}
                  Privacy Policy.
                {/* </NavLink>{" "} */}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;