import axios from "axios";
import { Config } from "../Config";

class ChatNexoBackend {
  constructor() {
    this.baseUrl = Config.CHAT_NEXO_BASE_URL;
  }

  async signUp({ email, password, userName, mobileNumber, countryCode }) {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.baseUrl}/v1/auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { email, password, userName, mobileNumber, countryCode },
      };

      return await axios.request(config);
    } catch (error) {
      return error.response;
    }
  }

  async verifyOtp({ email, otp }) {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.baseUrl}/v1/auth/verify-otp`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { email, otp },
      };
      return await axios.request(config);
    } catch (error) {
      return error.response;
    }
  }

  async login({ email, password }) {
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${this.baseUrl}/v1/auth/login`,
        headers: {
          "Content-Type": "application/json",
        },
        data: { email, password },
      };

      return await axios.request(config);
    } catch (error) {
      return error.response;
    }
  }
}

export const chatNexoBackend = new ChatNexoBackend();
