import React from "react";
import "../scss/refundpolicy.scss";

import Navbar from "../../../components/navbar/jsx/Navbar";
import Footer from "../../../components/footer/jsx/Footer";

import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";

const RefundPloicy = () => {
  return (
    <>
      <div className="termsrefundpolicy1">
        <div className="terms1_navbar">
          <Navbar />
        </div>

        <div className="trems1_banner">
          <div className="terms1_banner_banner">
            <h3>Refund Policy</h3>
            <VerifiedUserOutlinedIcon className="icon1" />
          </div>
        </div>

        <div className="terms1_interpretation">
          <div className="terms1_ques_ans">
            <h4>Last updated April 29, 2024 </h4>
          </div>

          <div className="terms1_ques_ans">
            <p>Thank you for subscribing to ChatNexo! We value your satisfaction and are committed to providing the best service possible.</p>
            <p>If for any reason you are not completely satisfied with your subscription, we offer a straightforward refund policy:</p>
            <h1>Eligibility for Refunds</h1>
            <p>
            Refunds are applicable only for the initial subscription period. Subsequent renewals or upgrades are non-refundable.{" "}
            </p>
            <p>
            Refund requests must be made within 30 days of the initial purchase.{" "}
            </p>
            <h1>Refund Process</h1>
            <p>
            To initiate a refund, please contact our support team at {" "}
              <span
                style={{
                  color: "rgb(132,175,188)",
                  textDecoration: "underline",
                }}
              >
                support@chatnexo.com 
              </span>{" "}
              with your subscription details.{" "}
            </p>
            <p>
            Our team will review your request and confirm eligibility within 2 business days.{" "}
            </p>
            <h1>Refund Timeline</h1>
            <p>
            Once approved, refunds will be processed within 1-2 weeks.{" "}
            </p>
            <p>
            The refunded amount will be credited to the original payment method used during the purchase.{" "}
            </p>
            <h1>Exclusions</h1>
            <p>
            Refunds are not applicable for discounted subscriptions or special promotional offers.{" "}
            </p>
            <p>
            Refunds are only processed for the net subscription amount, excluding any transaction fees or taxes.
            </p>
          </div>
        </div>
      </div>
      <div className="terms1_footer">
        <Footer />
      </div>
    </>
  );
};

export default RefundPloicy;
