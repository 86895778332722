import React from "react";
import "../scss/footer.scss";

import logo from "../../../../src/assets/image/chat_logo_1.png";

import { Config } from "../../../Config";
import { ROUTES } from "../../../utils/Constansts";

// import InstagramIcon from '@mui/icons-material/Instagram';
// import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import XIcon from '@mui/icons-material/X';

import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  const clickme = (item) => {
    navigate(item);
  };
  return (
    <div className="footer">
      <div className="footer_main">
        <div className="footer_main_contact">
          <div className="footer_main_contact_logo">
            <div className="footer_logo">
              <img src={logo} alt="" />
              <h3>Chat Nexo</h3>
            </div>
            <div className="footer_location">
              <p>T-Hub , Hyderabad, Telangana</p>
              <p>+91 8179422002</p>
            </div>
          </div>

          <div className="footer_product-contactus">
            <div className="footer_features">
              <h4>Products</h4>
              <p>Features</p>
              <p onClick={() => clickme(ROUTES.PRICING)}>Pricing</p>
              {/* <p>Resources</p>
                        <p>Vision</p> */}
            </div>
            <div className="footer_features">
              <h4>Contact us</h4>
              <p>Email Support</p>
              {/* <p>Facebook</p> */}
              <a href={Config.CHAT_NEXO_LINKDIN} target="_blank" rel="noopener noreferrer">
                Linkedin
              </a>
              {/* <p>X (Formely Twitter)</p> */}
            </div>
            <div className="footer_features">
              <h4>Company</h4>
              <p onClick={() => clickme(ROUTES.TERMS_CONDITIONS)}>
                Terms & Conditions
              </p>
              <p onClick={() => clickme(ROUTES.PRIVACY)}>Privacy policy</p>
              {/* <p>Careers</p> */}
              {/* <p>Master service agreement</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer_copyright">
        <div className="footer_copy_wrap">
          <p>Copyright @ 2024 , A Product by Stalcon Solutions Pvt. Ltd</p>
          <div className="footer_copyright_icons">
            {/* <XIcon className='icons'/> */}
            {/* <InstagramIcon className='icons'/> */}
            {/* <FacebookIcon className='icons' /> */}
            <a
              href={Config.CHAT_NEXO_LINKDIN}
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon className="icons" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
