import React from "react";
import "../scss/error.scss";
import FOF from "../../../assets/image/404.gif";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../utils/Constansts";

const Error = () => {
  const navigate = useNavigate();
  const click = (item) => {
    navigate(item);
  };
  return (
    <div className="error">
      <img src={FOF} alt="" />
      <button onClick={() => click(ROUTES.HOME)}>Go to Home</button>
    </div>
  );
};

export default Error;
