import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../scss/modal1.scss";
import { Config } from "../../../Config";

const Modal1 = ({ show, onClose, image, message }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onClose();
        window.location.href = Config.CHAT_NEXO_WHATSAAP_WEB;
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onClose, navigate]);

  return (
    <div className={`modal-overlay1 ${show ? "show" : ""}`}>
      <div className={`modal-content1 ${show ? "scale-up" : "scale-down"}`}>
        <img src={image} alt="Congratulations" />
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Modal1;