import React from "react";
import "../scss/features.scss";

import features from "../../../assets/image/features.png";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

import { useNavigate } from "react-router-dom";
import { Config } from "../../../Config";
import { ROUTES } from "../../../utils/Constansts";

const Features = () => {
  const navigate = useNavigate();
  const clickme = (item) => {
    navigate(item);
  };

  const clickroute = (item) => {
    window.open(item);
  };

  return (
    <div className="features">
      <div className="features_navbar">
        <h1>How do we compare?</h1>
        <p>How do we compare with Whatsapp Api based solutions?</p>
      </div>
      <div className="features_middle">
        <div className="features_middle_data">
          <div className="features_middle_card">
            <div className="features_middle_card_navbar">
              <h3>ChatNexo</h3>
            </div>
            <div className="features_middle_card_card">
              <h2>FEATURES INCLUDED :</h2>

              <div className="features_middle_card_card_tick">
                <TaskAltIcon
                  style={{ color: "rgb(0,198,67)" }}
                  className="icon_1"
                />
                <p>Easy Setup - 30s to start</p>
              </div>
              <div className="features_middle_card_card_tick">
                <TaskAltIcon
                  style={{ color: "rgb(0,198,67)" }}
                  className="icon_1"
                />
                <p>No cost per message</p>
              </div>
              <div className="features_middle_card_card_tick">
                <TaskAltIcon
                  style={{ color: "rgb(0,198,67)" }}
                  className="icon_1"
                />
                <p>Freedom to send any message fomate</p>
              </div>
              <div className="features_middle_card_card_tick">
                <CancelOutlinedIcon
                  style={{ color: "rgb(255,0,0)" }}
                  className="icon_1"
                />
                <p>Automation and chatbots</p>
              </div>
              <div className="features_middle_card_card_tick">
                <TaskAltIcon
                  style={{ color: "rgb(0,198,67)" }}
                  className="icon_1"
                />
                <p>Can use personal Whatsapp number to start</p>
              </div>
            </div>
          </div>

          <div className="features_middle_card">
            <div className="features_middle_card_navbar">
              <h3>WhatsApp API</h3>
            </div>
            <div className="features_middle_card_card">
              <h2>FEATURES INCLUDED :</h2>

              <div className="features_middle_card_card_tick">
                <CancelOutlinedIcon
                  style={{ color: "rgb(255,0,0)" }}
                  className="icon_1"
                />
                <p>Very Complex to setup</p>
              </div>
              <div className="features_middle_card_card_tick">
                <CancelOutlinedIcon
                  style={{ color: "rgb(255,0,0)" }}
                  className="icon_1"
                />
                <p>High Cost per message</p>
              </div>
              <div className="features_middle_card_card_tick">
                <CancelOutlinedIcon
                  style={{ color: "rgb(255,0,0)" }}
                  className="icon_1"
                />
                <p>Only Temptatized Messages can be sent</p>
              </div>
              <div className="features_middle_card_card_tick">
                <TaskAltIcon
                  style={{ color: "rgb(0,198,67)" }}
                  className="icon_1"
                />
                <p>Automation and Chatbots</p>
              </div>
              <div className="features_middle_card_card_tick">
                <CancelOutlinedIcon
                  style={{ color: "rgb(255,0,0)" }}
                  className="icon_1"
                />
                <p>Need a new Number to start</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="features_footer">
        <div className="fetures_footer_card">
          <div className="features_footer_card_half">
            <h1>We take your privacy seriously</h1>
            <p>
              Chat Nexo is actively pursuing SOC 2 Type 2 compliance,
              emphasizing a string commitment to your
              <br /> privacy throughout the process
            </p>
          </div>

          <div className="features_footer_card_image">
            <img src={features} alt="" />
            <button onClick={() => clickme(ROUTES.PRIVACY)}>
              Privacy policy <ArrowForwardOutlinedIcon className="icon_6" />{" "}
            </button>
          </div>
        </div>
      </div>

      <div className="features_bottom">
        <div className="features_bottom_data">
          <div className="features_bottom_heading">
            <h1>Start your free trial now!</h1>
            <p>
              Enjoy a free trial to experience a powerful WhatApp for work now.
            </p>
          </div>
          <div className="features_bottom_button">
            <button
              className="butt-1"
              onClick={() =>
                clickroute(
                  Config.CHAT_NEXO_CHROME_DOWNLOAD
                )
              }
            >
              Try for Free
            </button>
            <button
              className="butt-2"
              onClick={() =>
                clickroute(
                  Config.CHAT_NEXO_CALENDY
                )
              }
            >
              Contact Sales
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
