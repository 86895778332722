import "./App.css";
import Home from "./pages/Home/jsx/Home";
import { useState } from "react";
import Termsandconditions from "./pages/Terms&Conditions/jsx/Termsandconditions";
import Privacypolicy from "./pages/privacypolicy/jsx/Privacypolicy";
import Features from "./pages/Features/jsx/Features";
import Footer from "../src/components/footer/jsx/Footer";
import Productivity from "./pages/productivity/jsx/Productivity";
import Pricing from "./pages/pricing/jsx/Pricing";
import Login from "./pages/login/jsx/login";
import Error from "./pages/error/jsx/Error";
import Signup from "./pages/signup/jsx/Signup";
import Scrachcard from "./features/scrach card/jsx/Scrachcard";
import Compareplans from "./pages/pricing/jsx/Compareplans";
import Askquestions from "./pages/pricing/jsx/Askquestions";
import Productaction from "./pages/pricing/jsx/Productaction";
import Paymentprocessing from "./pages/payment_processing/jsx/Paymentprocessing";

import { Routes, Route } from "react-router-dom";
import { ROUTES } from "./utils/Constansts";
import ScrollToTop from "./ScrollToTop";
import RefundPloicy from "./pages/refundpolicy/jsx/Refundpolicy";

function App() {
  const [accestoken,setAccestoken]=useState('');
  
  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route
          path={ROUTES.HOME}
          element={
            <>
              <Home />
              <Productivity />
              <Features />
              <Footer />
            </>
          }
        />
        <Route path={ROUTES.PRIVACY} element={<><Privacypolicy /><Footer/></>} />
        <Route path={ROUTES.REFUND_POLICY} element={<RefundPloicy />} />
        <Route
          path={ROUTES.TERMS_CONDITIONS}
          element={<><Termsandconditions /> <Footer/> </>}
        />
        <Route
          path={ROUTES.PRICING}
          element={
            <>
              <Pricing setAccestoken={setAccestoken} />
              <Compareplans/>
              <Askquestions/>
              <Productaction/>
              <Footer />
            </>
          }
        />
        <Route
          path={ROUTES.LOGIN}
          element={
            <>
              <Login setAccestoken={setAccestoken} />
            </>
          }
        />

        <Route
          path={ROUTES.PAYMENT_PROCESSING}
          element={
            <>
              <Paymentprocessing  accestoken={accestoken}/>
            </>
          }
        />

        <Route
          path={ROUTES.SIGNUP}
          element={
            <>
              <Signup />
            </>
          }
        />
        <Route
          path={ROUTES.SCRACH}
          element={
            <>
              {/* <Navbar/> */}
              <Scrachcard />
              {/* <Footer/> */}
            </>
          }
        />
        <Route
          path={ROUTES.ERROR}
          element={
            <>
              <Error />
            </>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
