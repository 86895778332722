import React, { useState } from 'react';
import "../scss/scrachcard.scss";
import { ScratchCard } from "next-scratchcard";
import image from "../../../assets/image/checkmark.gif";
import backgroundimage from "../../../assets/image/image3.png";
import scrach from "../../../assets/image/scrach.png";
import stalcon_logo from "../../../assets/image/STalcon_logo_1.png"
// import Navbar from '../../../components/navbar/jsx/Navbar';
import Modal from './Modal';
import { Config } from '../../../Config';

const Scrachcard = () => {
    const [showModal, setShowModal] = useState(false);
    const [showCursor, setShowCursor] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const handleComplete = () => {
        console.log('Scratch card completed!');
        setShowModal(true);
        setShowCursor(false);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    

    const handleMouseEnter = () => {
        setShowCursor(true);
    };

    const handleMouseLeave = () => {
        setShowCursor(false);
    };

    const handleMouseMove = (event) => {
        if (showCursor) {
            setPosition({ x: event.clientX, y: event.clientY });
        }
    };

    const handel_redirect_to_stalcon = ()=>{
        window.open(Config.STALCON_WEBSITE, '_blank');
    }

    return (
        <div className='scrachcard_main'>

            <div className="scraechcard_card"  style={{backgroundImage:`url(${backgroundimage})`}}>
                <div className="scrachcard_card_nav">
                    <h5>1st Thub Community Fest</h5>
                </div>
                <div className="scrachcard_card_heading">
                    <h3>Hooray 🎉</h3>
                    <p>You’ve won a spooky scratch card</p>
                </div>

                <div className="scrachcard_image"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}
                >
                    <ScratchCard finishPercent={40} height={230} width={230} image={scrach} brushSize={50} onComplete={handleComplete}>
                        {showModal && <Modal show={showModal} onClose={handleCloseModal} image={image} message="Congratulations! Welcome to Chat Nexo!" />}
                    </ScratchCard>
                    {/* {showCursor && ( */}
                        <div
                            className={showCursor ? "custom-cursor":""}
                            style={{
                                left: `${position.x}px`,
                                top: `${position.y}px`,
                            }}
                        />
                    
                </div>

                <div className="scrachcard_card_heading_2">
                    <h3>Scratch the card to reveal your reward</h3>
                </div>
                <div className="scrachcard_card_heading_3" >
                    <p onClick={()=> handel_redirect_to_stalcon()} >Powered by <img src={stalcon_logo} alt="" /> </p>
                </div>
            </div>
        </div>
    );
}

export default Scrachcard;
