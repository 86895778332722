import "../scss/privacypolicy.scss";

import React from "react";
// import "../scss/terms&conditions.scss";

import Navbar from "../../../components/navbar/jsx/Navbar";
import Footer from "../../../components/footer/jsx/Footer";

import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// const terms = [
//   {
//     "text1": "Application",
//     "text2": "refers to the software program (extension, workspace, or web application) provided by the Company and downloaded by You on any electronic device, named Cooby."
//   },
//   {
//     "text1": "Application Store",
//     "text2": "refers to the digital distribution service operated and developed by Apple Inc. (Apple App Store) or Google Inc. (Google Play Store, Chrome Web Store) in which the Application has been downloaded."
//   },
//   {
//     "text1": "Affiliate",
//     "text2": "refers to an entity that controls, is controlled by, or is under common control with a party, where \"control\" means ownership of 50% or more of the shares, equity interest, or other securities entitled to vote for election of directors or other managing authority."
//   },
//   {
//     "text1": "Account",
//     "text2": "refers to a unique account created for You to access our Service or parts of our Service."
//   },
//   {
//     "text1": "Country",
//     "text2": "refers to California, United States."
//   },
//   {
//     "text1": "Company",
//     "text2": "(referred to as either \"the Company\", \"We\", \"Us\", or \"Our\" in this Agreement) refers to Cooby, Inc., 548 MARKET STREET PMB 16954 SAN FRANCISCO CA 94104-5401."
//   },
//   {
//     "text1": "Content",
//     "text2": "refers to content such as text, images, or other information that can be posted, uploaded, linked to, or otherwise made available by You, regardless of the form of that content."
//   },
//   {
//     "text1": "Device",
//     "text2": "refers to any device that can access the Service such as a computer, a cellphone, or a digital tablet."
//   },
//   {
//     "text1": "Feedback",
//     "text2": "refers to feedback, innovations, or suggestions sent by You regarding the attributes, performance, or features of our Service."
//   },
//   {
//     "text1": "Promotions",
//     "text2": "refer to contests, sweepstakes, or other promotions offered through the Service."
//   },
//   {
//     "text1": "Service",
//     "text2": "refers to the Application."
//   },
//   {
//     "text1": "Subscriptions",
//     "text2": "refer to the services or access to the Service offered on a subscription basis by the Company to You."
//   },
//   {
//     "text1": "Terms and Conditions",
//     "text2": "(also referred to as \"Terms\") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service."
//   },
//   {
//     "text1": "Third-party Social Media Service",
//     "text2": "refers to any services or content (including data, information, products, or services) provided by a third-party that may be displayed, included, or made available by the Service."
//   },
//   {
//     "text1": "You",
//     "text2": "means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable."
//   }
// ]

const Privacypolicy = () => {
  return (
    <>
      <div className="terms">
        <div className="terms_navbar">
          <Navbar />
        </div>

        <div className="trems_banner">
          <div className="terms_banner_banner2">
            <div className="privacy_terms">
              <h3>Privacy Policy</h3>
              <p>
                Please read these terms and conditions carefully before using
                Our Service
              </p>
            </div>

            <VerifiedUserOutlinedIcon className="icon1" />
          </div>
        </div>

        <div className="terms_interpretation">
          
          <div className="terms_ques_ans">
            <h4>Last updated April 29, 2024 </h4>
          </div>

          <div className="terms_ques_ans_1">
            <h1>TABLE OF CONTENTS </h1>
            <h4>
              <span>1. WHAT INFORMATION DO WE COLLECT?</span>{" "}
            </h4>
            <h4>
              <span>2. HOW DO WE PROCESS YOUR INFORMATION?</span>{" "}
            </h4>
            <h4>
              <span>
                3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
              </span>{" "}
            </h4>
            <h4>
              <span>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </span>
            </h4>
            <h4>
              <span>5. HOW LONG DO WE KEEP YOUR INFORMATION? </span>
            </h4>
            <h4>
              <span>6. HOW DO WE KEEP YOUR INFORMATION SAFE? </span>
            </h4>
            <h4>
              <span>7. WHAT ARE YOUR PRIVACY RIGHTS?</span>{" "}
            </h4>
            <h4>
              {" "}
              <span> 8. CONTROLS FOR DO-NOT-TRACK FEATURES </span>{" "}
            </h4>
            <h4>
              <span> 9. DO WE MAKE UPDATES TO THIS NOTICE? </span>
            </h4>
            <h4>
              {" "}
              <span> 10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>{" "}
            </h4>
            <h4>
              {" "}
              <span>
                11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT
                FROM YOU?{" "}
              </span>
            </h4>
          </div>

          <div className="terms_ques_ans">
            <p className="p1">
              This summary provides key points from our privacy notice, but you
              can find out more details about any of these topics by clicking
              the link following each key point or by using{" "}
              <span>our table of contents</span> below to find the section you
              are looking for.{" "}
            </p>
            <p>
              {" "}
              <span className="p2">
                What personal information do we process?
              </span>{" "}
              When you visit, use, or navigate our Services, we may process
              personal information depending on how you interact with us and the
              Services, the choices you make, and the products and features you
              use. Learn more about{" "}
              <span>personal information you disclose to us. </span>{" "}
            </p>
            <p>
              {" "}
              <span className="p2">
                {" "}
                Do we process any sensitive personal information ?{" "}
              </span>{" "}
              We do not process sensitive personal information.{" "}
            </p>
            <p>
              {" "}
              <span className="p2">
                Do we receive any information from third parties?
              </span>
              We do not receive any information from third parties.{" "}
            </p>
            <p>
              {" "}
              <span className="p2">How do we process your information?</span> We
              process your information to provide, improve, and administer our
              Services, communicate with you, for security and fraud prevention,
              and to comply with law. We may also process your information for
              other purposes with your consent. We process your information only
              when we have a valid legal reason to do so. Learn more about{" "}
              <span> how we process your information. </span>{" "}
            </p>
            <p>
              {" "}
              <span className="p2">
                In what situations and with which parties do we share personal
                information?
              </span>{" "}
              We may share information in specific situations and with specific
              third parties. Learn more about{" "}
              <span>
                when and with whom we share your personal information.
              </span>{" "}
            </p>
            <p>
              {" "}
              <span className="p2">
                How do we keep your information safe?
              </span>{" "}
              We have organizational and technical processes and procedures in
              place to protect your personal information. However, no electronic
              transmission over the internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Learn more about <span>how we keep your information safe.</span>{" "}
            </p>
            <p>
              {" "}
              <span className="p2">What are your rights?</span> Depending on
              where you are located geographically, the applicable privacy law
              may mean you have certain rights regarding your personal
              information. Learn more about <span>your privacy rights</span> .{" "}
            </p>
            <p>
              {" "}
              <span className="p2">How do you exercise your rights?</span> The
              easiest way to exercise your rights is by visiting{" "}
              <span> https://chatnexo.com/help,</span> or by contacting us. We
              will consider and act upon any request in a ccordance with
              applicable data protection laws.{" "}
            </p>
            <p>
              Want to learn more about what we do with any information we
              collect? <span>Review the privacy notice in full. </span>{" "}
            </p>
          </div>
          <div className="terms_ques_ans">
            <h1>1. WHAT INFORMATION DO WE COLLECT? </h1>
            <p>
              In Short: We collect personal information that you provide to us.{" "}
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you register on the Services, express an interest in
              obtaining information about us or our products and Services, when
              you participate in activities on the Services, or otherwise when
              you contact us.{" "}
            </p>
            <p>
              Personal Information Provided by You. The personal information
              that we collect depends on the context of your interactions with
              us and the Services, the choices you make, and the products and
              features you use. The personal information we collect may include
              the following:{" "}
            </p>
            <ul>
              <li>names </li>
              <li>phone numbers </li>
              <li>email addresses </li>
              <li>mailing addresses </li>
              <li>usernames </li>
              <li>passwords </li>
              <li>contact preferences </li>
              <li>billing addresses </li>
              <li>debit/credit card numbers </li>
            </ul>
            <p>
              Sensitive Information. We do not process sensitive information.{" "}
            </p>
            <p>
              Payment Data. We may collect data necessary to process your
              payment if you make purchases, such as your payment instrument
              number, and the security code associated with your payment
              instrument. All payment data is stored by razorpay. You may find
              their privacy notice link(s) here:{" "}
              <span>https://razorpay.com/privacy-policy/.</span>{" "}
            </p>
            <p>
              Social Media Login Data. We may provide you with the option to
              register with us using your existing social media account details,
              like your Facebook, Twitter, or other social media account. If you
              choose to register in this way, we will collect the information
              described in the section called "
              <span>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</span>" below.{" "}
            </p>
            <p>
              All personal information that you provide to us must be true,
              complete, and accurate, and you must notify us of any changes to
              such personal information.{" "}
            </p>
            <h3>Information automatically collected </h3>
            <p>
              In Short: Some information — such as your Internet Protocol (IP)
              address and/or browser and device characteristics — is collected
              automatically when you visit our Services.{" "}
            </p>
            <p>
              We automatically collect certain information when you visit, use,
              or navigate the Services. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our Services, and other
              technical information. This information is primarily needed to
              maintain the security and operation of our Services, and for our
              internal analytics and reporting purposes.
            </p>
            <p>The information we collect includes: </p>
            <ul>
              <li>
                Log and Usage Data. Log and usage data is service-related,
                diagnostic, usage, and performance information our servers
                automatically collect when you access or use our Services and
                which we record in log files. Depending on how you interact with
                us, this log data may include your IP address, device
                information, browser type, and settings and information about
                your activity in the Services (such as the date/time stamps
                associated with your usage, pages and files viewed, searches,
                and other actions you take such as which features you use),
                device event information (such as system activity, error reports
                (sometimes called "crash dumps"), and hardware settings).{" "}
              </li>
              <li>
                Device Data. We collect device data such as information about
                your computer, phone, tablet, or other device you use to access
                the Services. Depending on the device used, this device data may
                include information such as your IP address (or proxy server),
                device and application identification numbers, location, browser
                type, hardware model, Internet service provider and/or mobile
                carrier, operating system, and system configuration information.{" "}
              </li>
              <li>
                Location Data. We collect location data such as information
                about your device's location, which can be either precise or
                imprecise. How much information we collect depends on the type
                and settings of the device you use to access the Services. For
                example, we may use GPS and other technologies to collect
                geolocation data that tells us your current location (based on
                your IP address). You can opt out of allowing us to collect this
                information either by refusing access to the information or by
                disabling your Location setting on your device. However, if you
                choose to opt out, you may not be able to use certain aspects of
                the Services.{" "}
              </li>
            </ul>
          </div>
          <div className="terms_ques_ans">
            <h1>2. HOW DO WE PROCESS YOUR INFORMATION? </h1>
            <p>
              In Short: We process your information to provide, improve, and
              administer our Services, communicate with you, for security and
              fraud prevention, and to comply with law. We may also process your
              information for other purposes with your consent.{" "}
            </p>
            <p>
              We process your personal information for a variety of reasons,
              depending on how you interact with our Services, including:{" "}
            </p>

            <ul>
              <li>
                To facilitate account creation and authentication and otherwise
                manage user accounts. We may process your information so you can
                create and log in to your account, as well as keep your account
                in working order.{" "}
              </li>
              <li>
                To request feedback. We may process your information when
                necessary to request feedback and to contact you about your use
                of our Services.{" "}
              </li>
              <li>
                To protect our Services. We may process your information as part
                of our efforts to keep our Services safe and secure, including
                fraud monitoring and prevention.{" "}
              </li>
              <li>
                To identify usage trends. We may process information about how
                you use our Services to better understand how they are being
                used so we can improve them.{" "}
              </li>
            </ul>
          </div>
          <div className="terms_ques_ans">
            <h1>
              3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?{" "}
            </h1>
            <p>
              In Short: We may share information in specific situations
              described in this section and/or with the following third parties.{" "}
            </p>
            <p>
              We may need to share your personal information in the following
              situations:{" "}
            </p>
            <ul>
              <li>
                Business Transfers. We may share or transfer your information in
                connection with, or during negotiations of, any merger, sale of
                company assets, financing, or acquisition of all or a portion of
                our business to another company.
              </li>
              <li>
                When we use Google Analytics. We may share your information with
                Google Analytics to track and analyze the use of the Services.
                The Google Analytics Advertising Features that we may use
                include: Google Analytics Demographics and Interests Reporting,
                Remarketing with Google Analytics and Google Display Network
                Impressions Reporting. To opt out of being tracked by Google
                Analytics across the Services, visit{" "}
                <span>https://tools.google.com/dlpage/gaoptout.</span> You can
                opt out of Google Analytics Advertising Features through{" "}
                <span>Ads Settings</span> and Ad Settings for mobile apps. Other
                opt out means include{" "}
                <span>http://optout.networkadvertising.org/</span> and{" "}
                <span> http://www.networkadvertising.org/mobile-choice</span>.
                For more information on the privacy practices of Google, please
                visit the Google Privacy & Terms page.{" "}
              </li>
            </ul>
          </div>

          <div className="terms_ques_ans">
            <h1>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS? </h1>
            <p>
              In Short: If you choose to register or log in to our Services
              using a social media account, we may have access to certain
              information about you.{" "}
            </p>
            <p>
              Our Services offer you the ability to register and log in using
              your third-party social media account details (like your Facebook
              or Twitter logins). Where you choose to do this, we will receive
              certain profile information about you from your social media
              provider. The profile information we receive may vary depending on
              the social media provider concerned, but will often include your
              name, email address, friends list, and profile picture, as well as
              other information you choose to make public on such a social media
              platform.{" "}
            </p>
            <p>
              We will use the information we receive only for the purposes that
              are described in this privacy notice or that are otherwise made
              clear to you on the relevant Services. Please note that we do not
              control, and are not responsible for, other uses of your personal
              information by your third-party social media provider. We
              recommend that you review their privacy notice to understand how
              they collect, use, and share your personal information, and how
              you can set your privacy preferences on their sites and apps.{" "}
            </p>
          </div>
          <div className="terms_ques_ans">
            <h1>5. HOW LONG DO WE KEEP YOUR INFORMATION? </h1>
            <p>
              In Short: We keep your information for as long as necessary to
              fulfill the purposes outlined in this privacy notice unless
              otherwise required by law.{" "}
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting, or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than the period of time in which users have an account with
              us.{" "}
            </p>
            <p>
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.{" "}
            </p>
          </div>
          <div className="terms_ques_ans">
            <h1>6. HOW DO WE KEEP YOUR INFORMATION SAFE? </h1>
            <p>
              In Short: We aim to protect your personal information through a
              system of organizational and technical security measures.{" "}
            </p>
            <p>
              We have implemented appropriate and reasonable technical and
              organizational security measures designed to protect the security
              of any personal information we process. However, despite our
              safeguards and efforts to secure your information, no electronic
              transmission over the Internet or information storage technology
              can be guaranteed to be 100% secure, so we cannot promise or
              guarantee that hackers, cybercriminals, or other unauthorized
              third parties will not be able to defeat our security and
              improperly collect, access, steal, or modify your information.
              Although we will do our best to protect your personal information,
              transmission of personal information to and from our Services is
              at your own risk. You should only access the Services within a
              secure environment.{" "}
            </p>
          </div>

          <div className="terms_ques_ans">
            <h1>7. WHAT ARE YOUR PRIVACY RIGHTS? </h1>
            <h4>
              In Short: You may review, change, or terminate your account at any
              time.{" "}
            </h4>
            <p>
              Withdrawing your consent: If we are relying on your consent to
              process your personal information, which may be express and/or
              implied consent depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent at any time by contacting us by using the contact details
              provided in the section "
              <span>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>" below.{" "}
            </p>
            <p>
              However, please note that this will not affect the lawfulness of
              the processing before its withdrawal nor, when applicable law
              allows, will it affect the processing of your personal information
              conducted in reliance on lawful processing grounds other than
              consent.{" "}
            </p>
            <p>
              Opting out of marketing and promotional communications: You can
              unsubscribe from our marketing and promotional communications at
              any time by replying "STOP" or "UNSUBSCRIBE" to the SMS messages
              that we send, or by contacting us using the details provided in
              the section "
              <span>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>" below. You
              will then be removed from the marketing lists. However, we may
              still communicate with you — for example, to send you
              service-related messages that are necessary for the administration
              and use of your account, to respond to service requests, or for
              other non-marketing purposes.{" "}
            </p>
            <h4>Account Information </h4>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:{" "}
            </p>
            <p>
              Contact us using the contact information provided. Upon your
              request to terminate your account, we will deactivate or delete
              your account and information from our active databases. However,
              we may retain some information in our files to prevent fraud,
              troubleshoot problems, assist with any investigations, enforce our
              legal terms and/or comply with applicable legal requirements.{" "}
            </p>
            <p>
              If you have questions or comments about your privacy rights, you
              may email us at{" "}
              <span
                style={{
                  color: "rgb(132,175,188)",
                  textDecoration: "underline",
                }}
              >
                contact@chatnexo.com.
              </span>{" "}
            </p>
          </div>

          <div className="terms_ques_ans">
            <h1>8. CONTROLS FOR DO-NOT-TRACK FEATURES </h1>
            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.{" "}
            </p>
          </div>

          <div className="terms_ques_ans">
            <h1>9. DO WE MAKE UPDATES TO THIS NOTICE? </h1>
            <p>
              In Short: Yes, we will update this notice as necessary to stay
              compliant with relevant laws.{" "}
            </p>
            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </div>

          <div className="terms_ques_ans">
            <h1>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE? </h1>
            <p>
              If you have questions or comments about this notice, you may email
              us at{" "}
              <span
                style={{
                  color: "rgb(132,175,188)",
                  textDecoration: "underline",
                }}
              >
                contact@chatnexo.com
              </span>{" "}
              or contact us by post at:{" "}
            </p>
            <p>
              stalcon solutions <br />
              Raidurgam panmaktha <br />
              Hyderabad Knowledge City, <br />
              Hyderabad, Telangana 500081 <br />
              india
            </p>
          </div>

          <div className="terms_ques_ans">
            <h1>
              11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?{" "}
            </h1>
            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it. To request to
              review, update, or delete your personal information, please visit:{" "}
              <span
                style={{
                  color: "rgb(132,175,188)",
                  textDecoration: "underline",
                }}
              >
                https://chatnexo.com/help.
              </span>{" "}
            </p>
          </div>
        </div>
        <div className="terms_footer">
          <Footer />
        </div>

        {/* </div> */}
      </div>
      
    </>
  );
};

export default Privacypolicy;
