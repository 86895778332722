import React from 'react';
import "../scss/productaction.scss";


const Productaction = () => {
  return (
    <div className='prod_action' >
        <div className="prod_action_card">
            <h1>Take our product for a test drive at no cost.</h1>
            <p>Start right away at no cost with our free plan, lifetime access</p>
            <button>Get Started</button>
           
        </div>

    </div>
  )
}

export default Productaction