import React, { useState } from "react";
import "../scss/navbar.scss";
import logo from "../../../assets/image/chat_logo.png";
import toast from "react-hot-toast";

import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { NavLink } from "react-router-dom";
import { ROUTES } from "../../../utils/Constansts";
import { Config } from "../../../Config";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);


  const handelcommingsoon = ()=>{
    toast.success("Coming Soon");
  }

  const clickroute = (item) => {
    window.open(item);
  };

  const handelclick1 = () => {
    setOpen1(!open1);
  };

  const handelclick = () => {
    setOpen(!open);
  };

  return (
    <div className="navbar">
      <NavLink to={ROUTES.HOME} className="nav-link_1">
      <div className="navbar_logo">
        
          <img src={logo} alt="" />
        
        <h4>Chat Nexo</h4>
      </div>
      </NavLink>
      <div className={open ? "navbar_routes active" : "navbar_routes"}>
        <NavLink to={ROUTES.HOME} className="nav-link">
          <p>Home</p>
        </NavLink>
        <p onClick={()=>handelcommingsoon()} >Features</p>
        <NavLink to={ROUTES.PRICING} className="nav-link">
          <p>Pricing</p>
        </NavLink>

        <p
          onClick={handelclick1}
          className={open1 ? "resourse_data1 active" : "resourse_data1"}
        >
          Resources{" "}
          {open1 ? (
            <KeyboardArrowUpIcon onClick={handelclick1} />
          ) : (
            <KeyboardArrowDownIcon onClick={handelclick1} />
          )}{" "}
        </p>
        <div className={open1 ? "resourse_data active" : "resourse_data"}>
          <div
            className={
              open1 ? "resource_data_main active" : "resource_data_main"
            }
          >
            <h4>Reference</h4>
            <NavLink to={ROUTES.REFUND_POLICY} className="nav-link">
              <p>Refund</p>
            </NavLink>

            <NavLink to={ROUTES.PRIVACY} className="nav-link">
              <p>Privacy Policy</p>
            </NavLink>

            <NavLink to={ROUTES.TERMS_CONDITIONS} className="nav-link">
              <p>Terms & Conditions</p>
            </NavLink>

            
          </div>
        </div>

        <p>Vision</p>
      </div>
      <div
        className={`${open ? "navbar_buttons active" : "navbar_buttons"} ${
          open1 ? "navbar_buttons activepluse" : "navbar_buttons"
        }`}
      >
        <button
          className="but-1"
          onClick={() =>
            clickroute(
              Config.CHAT_NEXO_CALENDY
            )
          }
        >
          BOOK DEMO
        </button>
        <button
          className="but-2"
          onClick={() =>
            clickroute(
              Config.CHAT_NEXO_CHROME_DOWNLOAD
            )
          }
        >
          DOWNLOAD
        </button>
      </div>

      <div className="mobile">
        {open ? (
          <ClearRoundedIcon onClick={handelclick} />
        ) : (
          <MenuRoundedIcon onClick={handelclick} />
        )}
      </div>
    </div>
  );
};

export default Navbar;
