import React,{useState,useEffect} from "react";
import "../scss/pricing.scss";
import Navbar from "../../../components/navbar/jsx/Navbar";
import axios from "axios";
import useQuery from "../../../hooks/useQuery";
import { Config } from "../../../Config";
import { ROUTES } from "../../../utils/Constansts";
import { useNavigate } from "react-router-dom";
import Switch from "@mui/material/Switch";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import toast from "react-hot-toast";

const free = [
  { name: "Label/Funnels"},
  { name: "Quick reply with attachment" },
  { name: "Schedule Messages" },
  { name: "Follow-ups, Tasks, Notes" },
  {name:"User Properties"},
  { name:"AI Reply"},
  { name:"Integrations"},
  { name:"Mentionns"},
  { name:"Team Inbox"},
  { name:"Analytics"},
  { name:"Broadcasting messages"},
];


const Pricing = ({setAccestoken}) => {
  // const query = useQuery();
  const [isChecked, setIsChecked] = useState(false);
  const [toggle,setToggle]=useState(false);
  const [plans, setPlans] = useState([]);
  const [currentData, setCurrentData] = useState({});
  const [finaldata,setFinaldata]=useState({});
  const query = useQuery();
  const navigate=useNavigate()

  // const ACCESS_TOKEN_FROM_LOCAL=localStorage.getItem('accessToken')
  const ACCESS_TOKEN_FROM_QUERY=query.get("accessToken") || "";

  const ACCESS_TOKEN=ACCESS_TOKEN_FROM_QUERY ||localStorage.getItem('accessToken');
  
  // useEffect(() => {
    // setAccestoken(ACCESS_TOKEN);
    // localStorage.setItem('accessToken',ACCESS_TOKEN)
  // }, [ACCESS_TOKEN, setAccestoken]);
  
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${Config.CHAT_NEXO_BASE_URL}/v1/subscription/all-plans`, {
          headers: {
            'Authorization': `Bearer ${ACCESS_TOKEN}`
          }
        });
        setPlans(response.data.result);
      } catch (error) {
        console.error("Error fetching the plans:", error);
      }
    };
    fetchPlans();
  }, [plans.result,ACCESS_TOKEN]);

  
  useEffect(() => {
    const transformedData = {};
    plans.forEach(item => {
      if (!transformedData[item.type]) {
        transformedData[item.type] = [];
      }
      transformedData[item.type].push({
        id: item._id,
        monthly: item.monthlyPrice,
        yearly: item.YearlyPrice,
        min:item.users.min,
        max: item.users.max,
      });
    });
    setFinaldata(transformedData);

    if (transformedData.BEGINNER && transformedData.BEGINNER.length > 0) {
      setCurrentData({
        type:"Begineer",
        id:transformedData.BEGINNER[0].id,
        min:transformedData.BEGINNER[0].min,
        max:transformedData.BEGINNER[0].max,
        monthly: transformedData.BEGINNER[0].monthly,
        yearly: transformedData.BEGINNER[0].yearly
    })
  }
    // Removed it because it fetching data every time when toggles the button 
  }, [plans]);



  const handleTogglekey = ()=>{
    setToggle(!toggle)
  }

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handeldropdownTeam = (item) => {
    setToggle(!toggle);
    setCurrentData({
      type:"Team",
      id:finaldata.TEAM[0].id,
      min:finaldata.TEAM[0].min,
      max:finaldata.TEAM[0].max,
      monthly: finaldata.TEAM[0].monthly,
      yearly: finaldata.TEAM[0].yearly
    })
  };


  const handeldropdownAdvance = (item) => {
    setToggle(!toggle);
    setCurrentData({
      type:"Advance",
      id:finaldata.ADVANCE[0].id,
      min:finaldata.ADVANCE[0].min,
      max:finaldata.ADVANCE[0].max,
      monthly: finaldata.ADVANCE[0].monthly,
      yearly: finaldata.ADVANCE[0].yearly
    })
  };

  const handelpay = ()=>{
    if (ACCESS_TOKEN === null || ACCESS_TOKEN.trim() === "") {
      toast.error("Please Login");
      navigate(ROUTES.LOGIN);
    }
    else{
      localStorage.setItem("accessToken",ACCESS_TOKEN);
      const url = `${Config.CHAT_NEXO_BASE_URL}/v1/subscription/upgrade`;
      const data_send={
      planId:currentData.id,
      isMonthly:!isChecked
    }
    axios.post(url, data_send, {
        headers: {
          'Authorization': `Bearer ${ACCESS_TOKEN}`
          }
        })
        .then(response => {
          window.location.href=response.data.result.redirectUrl
        })
        .catch(error => {
            console.error('Error:', error);
        });
    }
  }
  
  return (
    <div className="pricing">
      <div className="pricing_navbar">
        <Navbar />
      </div>
      <div className="pricing_button">
        <button>Pricing</button>
      </div>
      <div className="pricing_heading">
        <h1>
        "Ready to integrate WhatsApp with your favorite work <br /> tools? Explore our pricing tags now!"
        </h1>
        <p>Experience the power of WhatsApp for work with a free trial today.</p>
      </div>
      <div className="pricing_toggle">
        <div className="pricing_toggle_conent">
          <h3>Monthly price </h3>
          <div className="switch">
            <Switch
            className="togle_button"
              checked={isChecked}
              onChange={handleToggle}
            />
          </div>
          <h3>Yearly price </h3>
          <p>15% off</p>
        </div>
      </div>

      <div className="pricing_cards">

        <div className="pricing_card_free">
          <div className="pricing_card_free_heading">
            <h1>Free</h1>
            <p>For individuals and team getting <br /> started</p>
          </div>
          <div className="pricing_card_free_price">
            <h1>{finaldata.TEAM && finaldata.FREE.length > 0 ? 
                            (isChecked ? finaldata.FREE[0].yearly : finaldata.FREE[0].monthly) + "/-" 
                            : "loading..."}
            </h1>
            <p>7 days Unlimited Access</p>
          </div>
          <div className="pricing_card_free_button">
            <button>Start free trial</button>
          </div>
          <div className="pricing_card_free_contain">
            {
              free.map((item,index)=>(

                <div className="check" key={index}  >
                  <CheckRoundedIcon className="icon_1"/>
                  <p>{item.name}</p>
                </div>
              ))
            }
          </div>
        </div>

        <div className="pricing_card_premium">

          <div className="pricing_card" style={{ height: toggle ? "65vh" : "40vh" }} >

            <div className="pricing_card_free_heading">
              <h1>Premium</h1>
              <p>15% off</p>
            </div>
            <div className="pricing_card_free_heading_2" style={{ height: toggle ? "37vh" : "12vh" }} >
              <div className="pricing_card_free_subheading">
                <h2>{currentData.type}</h2>
                <div className="toggle">
                {toggle ? (
                    <KeyboardArrowUpRoundedIcon onClick={handleTogglekey} />
                  ) : (
                    <KeyboardArrowDownRoundedIcon onClick={handleTogglekey} />
                  )}
                </div>

              </div>

              {toggle && 
                  
                  <div className="dropdown">
                    <div className="dropdown_top" onClick={()=> handeldropdownTeam(`${isChecked ? finaldata.TEAM[0].yearly+"/-"  : finaldata.TEAM[0].monthly+"/-" }`)}>

                      <div className="dropdown_top_heading">
                        <h3>Team</h3>
                        <h3>{finaldata.TEAM && finaldata.TEAM.length > 0 ? 
                            (isChecked ? finaldata.TEAM[0].yearly : finaldata.TEAM[0].monthly) + "/-" 
                            : "loading..."}
                        </h3>
                      </div>
                      <div className="dropdown_top_subheading">
                        <p>Per month / {finaldata.TEAM && finaldata.TEAM.length > 0 ? 
                                        (finaldata.TEAM[0].min)
                                      : "loading..."}-{finaldata.TEAM && finaldata.TEAM.length > 0 ? 
                                        (finaldata.TEAM[0].max)
                                      : "loading..."} users</p>
                      </div>

                    </div>
                    <div className="dropdown_down" onClick={()=> handeldropdownAdvance(`${isChecked ? finaldata.ADVANCE[0].yearly+"/-"  : finaldata.ADVANCE[0].monthly+"/-"  }`) } >

                    <div className="dropdown_top_heading">
                        <h3>Advance</h3>
                        <h3>{finaldata.ADVANCE && finaldata.ADVANCE.length > 0 ? 
                            (isChecked ? finaldata.ADVANCE[0].yearly : finaldata.ADVANCE[0].monthly) + "/-" 
                            : "loading..."}
                        </h3>
                      </div>
                      <div className="dropdown_top_subheading">
                        <p>Per month / {finaldata.ADVANCE && finaldata.ADVANCE.length > 0 ? 
                                        (finaldata.ADVANCE[0].min)
                                      : "loading..."}-{finaldata.ADVANCE && finaldata.ADVANCE.length > 0 ? 
                                        (finaldata.ADVANCE[0].max)
                                      : "loading..."} users</p>
                        
                      </div>

                    </div>
                  
                  </div> 
              }

              <p>For small to medium sized business that have a smaller target audience</p>
              
            </div>

            <div className="pricing_card_free_price">
              <h1>
                  {(isChecked ? currentData.yearly : currentData.monthly) + "/-" }
              </h1>
              <p>Per month / {currentData.min}-{currentData.max} users</p>
            </div>
            <div className="pricing_card_free_button">
              <button onClick={()=>handelpay()} >Pay</button>
            </div>

          </div>
          
          <div className="pricing_card_free_contain">
            {
              free.map((item,index)=>(

                <div className="check" key={index}  >
                  <CheckRoundedIcon className="icon_1" />
                  <p>{item.name}</p>
                </div>
              ))
            }
          </div>
        </div>

        

        <div className="pricing_card_free">
          <div className="pricing_card_free_heading">
            <h1>Enterprise</h1>
            <p>For Organizations with more complex <br /> requirements</p>
          </div>
          <div className="pricing_card_free_price">
            <h2>Let’s talk</h2>
            <p>10 + Users</p>
          </div>
          <div className="pricing_card_free_button">
            <button>Contact us</button>
          </div>
          <div className="pricing_card_free_contain">
            {
              free.map((item,index)=>(

                <div className="check" key={index}  >
                  <CheckRoundedIcon className="icon_1" />
                  <p>{item.name}</p>
                </div>
              ))
            }
          </div>
        </div>
      </div>

    </div>
  );
};

export default Pricing;
