export const Config = {
  CHAT_NEXO_BASE_URL: "https://production-api.chatnexo.com",
  CHAT_NEXO_CHROME_DOWNLOAD:
    "https://chromewebstore.google.com/detail/chat-nexo-whatsapp-extens/bfcjngbdidefnaifbnbamfapiailjdkj",
  CHAT_NEXO_CALENDY:
    "https://calendly.com/chatnexo/chatnexo-demo?month=2024-05",
  CHAT_NEXO_LINKDIN: "https://in.linkedin.com/company/stalconsolutions",
  CHAT_NEXO_WHATSAPP: "https://api.whatsapp.com/send?phone=123456789",
  GOOGLE_FORMS:
    "https://docs.google.com/forms/d/1gLsV5nBU-6iGvmC9mAUyiGAAaX9gwxzIgZbVEGoopdo/viewform?edit_requested=true",
  STALCON_WEBSITE: "https://stalcon.in",
  CHAT_NEXO_WHATSAAP_WEB:"https://web.whatsapp.com/",
}
