import React,{useState} from 'react'
import "../scss/askquestions.scss";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';

const dropdown_data =[
    {name:"What payment methods do you accept?",details:"We accept  payments via major credit cards (Visa, Mastercard, American Express) as well as PayPal for your convenience."},
    {name:"Is there a contract or long-term commitment?",details:"No, there is no long-term commitment required. Our plans are flexible with a month-to-month billing cycle. You can cancel or change your plan at any time without any penalties."},
    {name:"Can I change my plan later?",details:"Absolutely! You can upgrade or downgrade your plan at any time. The changes will take effect immediately, and any cost adjustments will be prorated."},
    {name:"Do you offer discounts for non-profits or educational institutes?",details:"Yes, we offer special discounts for non-profits and educational institutions. Please contact our support team for more details and to see if you qualify."},
    {name:"Can I add or remove users from my plam?",details:"Yes, you can easily add or remove users from your plan at any time. Changes will be reflected in your billing cycle accordingly."}
]


const Askquestions = () => {
    const [expanded, setExpanded] = useState(Array(dropdown_data.length).fill(false));
    const handleToggle = (index) => {
        setExpanded((prevExpanded) =>
            prevExpanded.map((isExpanded, i) => (i === index ? !isExpanded : isExpanded))
        );
    };
  return (
    <div className='askques'>
        <div className="askques_main">
            <div className="askques_main_left">
                <h1>Frequently Asked <br /> Questions</h1>

            </div>
            <div className="askques_main_right">

                {
                    dropdown_data.map((item,index)=>(

                        <Accordion key={index}
                        className='accordian1'
                        expanded={expanded[index]}
                        onChange={() => handleToggle(index)} >
                            <AccordionSummary
                                className='acoordian_heading1'
                                expandIcon={
                                    <div className='icon_3'>
                                        {expanded[index] ? (
                                            <RemoveRoundedIcon style={{ fontSize: "25px" }} />
                                        ) : (
                                            <AddRoundedIcon style={{ fontSize: "25px" }} />
                                        )}
                                    </div>
                                }
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                >
                                    <p>{item.name}</p>
                                
                            </AccordionSummary>
                            
                            <AccordionDetails className='accordian_details1' >
                                <p>{item.details}</p>
                            </AccordionDetails>
                        </Accordion>


                    ))
                }

            </div>

        </div>

    </div>
  )
}

export default Askquestions